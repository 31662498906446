<template>
    <div>
        <page-header :title="title" :items="items" />

        <div class="row">
            <div class="col-12 col-md-6">
                Dashboard
            </div>
        </div>
    </div>
</template>

<script>
import config from '@config';

export default {
    page: {
        title: 'Dashboard',
        meta: [{ name: 'description', content: config.description }]
    },

    data() {
        return {
            title: 'Dashboard',
            items: [
                {
                    text: 'NovoCare',
                    to: '/'
                },
                {
                    text: 'Dashboard',
                    active: true
                }
            ]
        };
    }
};
</script>
